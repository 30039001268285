//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-725:_6228,_2760,_2536,_2632,_213,_1112,_5948,_8480;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-725')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-725', "_6228,_2760,_2536,_2632,_213,_1112,_5948,_8480");
        }
      }catch (ex) {
        console.error(ex);
      }