//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-725:_7756,_5060,_6900,_7424,_392,_3888,_4120,_8248;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-725')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-725', "_7756,_5060,_6900,_7424,_392,_3888,_4120,_8248");
        }
      }catch (ex) {
        console.error(ex);
      }