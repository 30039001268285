//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-725:_4188,_6216,_492,_5332,_24,_208,_6528,_7584;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-725')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-725', "_4188,_6216,_492,_5332,_24,_208,_6528,_7584");
        }
      }catch (ex) {
        console.error(ex);
      }